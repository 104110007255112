.react-select__control.react-select__control {
   /* display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #181C32;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.475rem;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out*/
}

.react-select-w50__control.react-select-w50__control{
    width: 250px;
}
.react-select__single-value.react-select__single-value ,.react-select__multi-value__label.react-select__multi-value__label{
    color: #5E6278;
    font-size: 1.15rem;
}